import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as Logo } from '../logo_samoyede.svg';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const StyledAppBar = styled(AppBar)({
  position: 'sticky',
  top: 0,
});

const StyledLogo = styled(Logo)({
  height: '48px',
  width: '48px',
});

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 600,
});

const menuItems = [
  {
    label: 'Home',
    href: '/',
    disabled: false,
    target: '_self',
  },
  {
    label: 'LP',
    href: '/lp',
    disabled: true,
    target: '_self',
  },
  {
    label: 'Earn',
    href: '/earn',
    disabled: true,
    target: '_self',
  },
  {
    label: 'WP',
    href: 'https://arcalion.gitbook.io/aisamoyed-wp/about/ai-samoyed',
    disabled: false,
    target: '_blank',
  },
  {
    label: 'Mint',
    href: 'https://opensea.io/collection/aisamoyed/drop',
    disabled: false,
    target: '_blank',
  },
];

const StyledMenuItemsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export default function SamoyedAppBar() {
  return (
    <ThemeProvider theme={theme}>
      <StyledAppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="logo">
            <StyledLogo />
          </IconButton>
          <div style={{ flexGrow: 1 }}>
            <StyledMenuItemsContainer>
            {menuItems.map((item) => (
              <StyledButton key={item.label} href={item.href} disabled={item.disabled} target={item.target} color="inherit">
                {item.label}
              </StyledButton>
            ))}
            </StyledMenuItemsContainer>
          </div>
          <div style={{ flexGrow: 0 }}>
            <IconButton edge="end" color="inherit" aria-label="twitter" href='https://twitter.com/AI_Samoyed' target="_blank">
              <TwitterIcon />
            </IconButton>
          </div>
        </Toolbar>
      </StyledAppBar>
    </ThemeProvider>
  );
}